import { compareAsc, compareDesc, format } from 'date-fns';

export class OcpUtilsDate {
  static compare(a: Date, b: Date, isAsc = true): number {
    return isAsc ? compareAsc(a, b) : compareDesc(a, b);
  }

  static formatWithKeyword(
    date: Date | string | null | undefined,
    formatKey: 'full' | 'long' | 'medium' | 'short',
  ): string {
    if (!date) {
      return '';
    }

    const dateFnsFormatMap: Record<string, string> = {
      short: 'MM/dd/yyyy, h:mm a', // Equivalent to Angular 'short'
      medium: 'MMM d, yyyy, h:mm:ss a', // Equivalent to Angular 'medium'
      long: 'MMMM d, yyyy, h:mm:ss a z', // Similar to Angular 'long'
      full: 'EEEE, MMMM d, yyyy, h:mm:ss a zzzz', // Similar to Angular 'full'
    };

    const formatString = dateFnsFormatMap[`${formatKey}`] || dateFnsFormatMap['short'];

    const parsedDate = typeof date === 'string' ? new Date(date) : date;

    return format(parsedDate, formatString);
  }
}
